export const blog_data = {
  es: [
    {
      id: 1,
      title:
        "EOL y CEAMED ofrecen curso para ayudantes enfocado en el manejo de la plataforma EOL",
      date: "29/09/2021",
      author: "Equipo Diseño Instruccional, Oficina Educación Online",
      description:
        "Esta iniciativa busca ser una guía para los ayudantes que trabajan en la plataforma eol, ofreciéndoles las herramientas necesarias para el manejo de este entorno virtual de aprendizaje.",
      post: "/posts/2021_09_29_herramientas_para_estudiantes/post_es.html",
      img: "/posts/2021_09_29_herramientas_para_estudiantes/image_square.jpg",
      img_large:
        "/posts/2021_09_29_herramientas_para_estudiantes/image_large.jpg",
      tags: ["EOL", "CEAMED", "Testimonios"],
    },
    {
      id: 2,
      title:
        "Ment.io: la herramienta de foro que está abriendo nuevas posibilidades de interacción en la plataforma EOL",
      date: "29/09/2021",
      author: "Equipo Diseño Instruccional, Oficina Educación Online",
      description:
        "Veterinaria, Medicina, Derecho, ICEI, Docencia, Beauchef, FACSO, son algunas de las facultades y unidades de la universidad que durante este semestre estarán llevando a cabo el pilotaje de la herramienta Ment.io en algunos cursos de la plataforma EOL",
      post: "/posts/2021_09_29_piloto_mentio/post_es.html",
      img: "/posts/2021_09_29_piloto_mentio/image_square.jpg",
      img_large: "/posts/2021_09_29_piloto_mentio/image_large.jpg",
      tags: ["Ment.io"],
    },
    {
      id: 3,
      title: "Herramienta de discusión Ment.io",
      date: "29/09/2021",
      author: "Equipo Diseño Instruccional, Oficina Educación Online",
      description: "Para conocer más del uso de la herramienta",
      post: "/posts/2021_09_29_intro_mentio/post_es.html",
      img: "/posts/2021_09_29_intro_mentio/image_01.png",
      img_large: "/posts/2021_09_29_intro_mentio/image_large.jpg",
      tags: ["Ment.io"],
    },
    {
      id: 4,
      title:
        "Innovar, participar, educar y aprender, son verbos poderosos en la Universidad de Chile",
      date: "29/09/2021",
      author: "Equipo Diseño Instruccional, Oficina Educación Online",
      description:
        "La metodología de la plataforma EOL puede cambiar la forma de enseñar y la forma de aprender de las y los estudiantes.",
      post: "/posts/2021_09_29_metodologia_eol/post_es.html",
      img: "/posts/2021_09_29_metodologia_eol/image_square.jpg",
      img_large: "/posts/2021_09_29_metodologia_eol/image_01.jpeg",
      tags: ["EOL", "Testimonios"],
    },
    {
      id: 5,
      title:
        "Documento - Entornos de enseñanza y aprendizaje mediados por tecnologías",
      date: "27/10/2021",
      author: "Oficina Educación Online",
      description: "Orientaciones para la docencia universitaria.",
      post: "/posts/2021_10_27_orientaciones_docencia_universitaria/post_es.html",
      img: "/posts/2021_10_27_orientaciones_docencia_universitaria/square.jpg",
      img_large:
        "/posts/2021_10_27_orientaciones_docencia_universitaria/image.jpg",
      tags: ["EOL", "VTI", "VAA", "Docencia"],
    },
    {
      id: 6,
      title: "¿Qué es Genial.ly y para qué sirve?",
      date: "13/06/2022",
      author: "Oficina Educación Online",
      description:
        "",
      post: "/posts/2022_06_13_que_es_genially/post_es.html",
      img: "/posts/2022_06_13_que_es_genially/genially-logo.jpeg",
      img_large: "/posts/2022_06_13_que_es_genially/genially-logo.jpeg",
      tags: ["EOL", "VTI", "Herramienta", "Docencia"],
    },
    {
      id: 7,
      title: "Manuales para la utilización de Zoom",
      date: "02/06/2022",
      author: "Oficina Educación Online",
      description:
        "Aclare dudas y mejore su experiencia en sus clases y reuniones online a través de Zoom",
      post: "/posts/2022_01_21_manuales_zoom/post_es.html",
      img: "/posts/2022_01_21_manuales_zoom/square.jpg",
      img_large: "/posts/2022_01_21_manuales_zoom/header.jpg",
      tags: ["EOL", "VTI", "Zoom", "Docencia"],
    },
    {
      id: 8,
      title: "Documento – Orientaciones para creación o adaptación de cursos y programas de postgrado a formato online ",
      date: "22/07/2022",
      author: "Oficina Educación Online",
      description: "",
      post: "/posts/2022_07_22_documento_docencia_postgrado/post_es.html",
      img: "/posts/2022_07_22_documento_docencia_postgrado/img1.jpg",
      img_large: "/posts/2022_07_22_documento_docencia_postgrado/img2.jpg",
      tags: ["EOL", "VTI", "VAA", "Docencia"],
    },
    {
      id: 9,
      title: "Conociendo EOL",
      date: "28/07/2022",
      author: "Oficina Educación Online",
      description:
        "Una de las metas de la Oficina de Educación Online (EOL), dependiente de la Vicerrectoría de Tecnologías de la Información, es generar capacidad en las y los docentes para la producción de cursos online, con la mayor independencia y autonomía.</br></br>La siguiente infografía cuenta en resumen qué es la Oficina EOL, cómo trabajar con nosotros y qué servicios entregamos.</br></br>Para profundizar aún más, les invitamos a revisar el curso “Conociendo EOL”, el cual está orientado a explorar la plataforma EOL y sus posibilidades <a href='https://eol.uchile.cl/courses/course-v1:eol+C1+2022_1/about' target='_blank'>(ver curso aquí).</a></br></br>",
      post: "/posts/2022_07_28_conociendo_eol/post_es.html",
      img: "/posts/2022_07_28_conociendo_eol/img1.jpg",
      img_large: "/posts/2022_07_28_conociendo_eol/img2.jpg",
      tags: ["EOL", "VTI"],
    },
    {
      id: 10,
      title: "Orientaciones para la docencia en salas híbridas",
      date: "03/08/2022",
      author: "Oficina Educación Online",
      description: "",
      post: "/posts/2022_08_03_orientaciones_salas_hibridas/post_es.html",
      img: "/posts/2022_08_03_orientaciones_salas_hibridas/img1.jpg",
      img_large: "/posts/2022_08_03_orientaciones_salas_hibridas/img2.jpg",
      tags: ["EOL", "VTI"],
    },
    {
      id: 11,
      title: "El video como recurso educativo",
      date: "09/08/2022",
      author: "Oficina Educación Online",
      description: "",
      post: "/posts/2022_08_09_video_como_recurso_educativo/post_es.html",
      img: "/posts/2022_08_09_video_como_recurso_educativo/img/cuadrado_blog.jpg",
      img_large: "/posts/2022_08_09_video_como_recurso_educativo/img/banner_blog.jpg",
      tags: ["EOL", "VTI", "Herramienta", "Docencia"],
    },
    {
      id: 12,
      title: "Catálogo Informativo: Herramientas IA ",
      date: "25/04/2023",
      author: "Oficina Educación Online",
      description: "",
      post: "/posts/2023_04_25_herramientas_ia/post_es.html",
      img: "/posts/2023_04_25_herramientas_ia/img/logo.jpg",
      img_large: "/posts/2023_04_25_herramientas_ia/img/logo.jpg",
      tags: ["EOL", "VTI", "VAA", "Docencia", "IA"],
    },
    {
      id: 13,
      title: "El Foro: Una herramienta poderosa para el aprendizaje en la era digital",
      date: "08/03/2024",
      author: "Oficina Educación Online",
      description: "",
      post: "/posts/2024_03_08_el_foro/post_es.html",
      img: "/posts/2024_03_08_el_foro/img/foro.jpeg",
      img_large: "/posts/2024_03_08_el_foro/img/foro.jpeg",
      tags: ["EOL", "VTI", "Herramientas", "Docencia"],
    },
    {
      id: 14,
      title: "La Importancia de los quizzes en la evaluación formativa",
      date: "13/06/2024",
      author: "Oficina Educación Online",
      description: "",
      post: "/posts/2024_06_13_importancia_quizzes/post_es.html",
      img: "/posts/2024_06_13_importancia_quizzes/img/quiz.jpg",
      img_large: "posts/2024_06_13_importancia_quizzes/img/quiz.jpg",
      tags: ["EOL", "VTI", "Docencia"],
    },
    {
      id: 15,
      title: "Metodología de Enseñanza: Aula Invertida",
      date: "28/06/2024",
      author: "Oficina Educación Online",
      description: "",
      post: "/posts/2024_06_28_metodologia_ensenianza_aula_invertida/post_es.html",
      img: "/posts/2024_06_28_metodologia_ensenianza_aula_invertida/img/wordcloud.png",
      img_large: "posts/2024_06_28_metodologia_ensenianza_aula_invertida/img/wordcloud.png",
      tags: ["EOL", "VTI", "Docencia"],
    },
  ],
};
